import http from '../request/index'

//获取页面模块配置
export const GetPageModuleInfo = (params) => {
  return http.post('/api/PageModule/GetPageModuleInfo', params)
}

//分销佣金排行榜
export const GetUserCommissionRankList = (params) => {
  return http.post('/api/UserCommisson/GetUserCommissionRankList', params)
}

//商品列表
export const GetProductList = (params) => {
  return http.post('/api/Product/GetProductList', params)
}

//盟主基础信息
export const GetUserInfo = () => {
  return http.get('/api/User/GetUserInfo')
}

//盟主账户信息
export const GetUserAccountInfo = () => {
  return http.post('/api/User/GetUserAccountInfo')
}

//创建用户
export const CreateUserInfo = (params) => {
  return http.post('/api/User/CreateUserInfo', params)
}

//短链转换服务
export const GetShortLink = (params) => {
  return http.get('/api/ShortLink/GetShortLink', params)
}

//获取最近20条分佣记录(轮播)
export const GetUserCommissionRotation = (params) => {
  return http.get('/api/UserCommisson/GetUserCommissionRotation', params)
}
