<template>
  <div class="tabbar">
    <router-view />
    <van-tabbar route
                active-color="#292933"
                inactive-color="#26252A"
                v-model="active"
                @change="handleChange">
      <van-tabbar-item replace to="/">
        <span>首页</span>
        <template #icon="props">
          <img class="icon" :src="props.active ? homeIcon.active : homeIcon.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/my">
        <span>我的</span>
        <template #icon="props">
          <img class="icon" style="width: 24px;height: 24px;" :src="props.active ? myIcon.active : myIcon.inactive" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  props: ['isAuthorized'],
  components: {},
  data() {
    return {
      active: 0,
      homeIcon: {
        active: require('@/sprite/homeOn.jpg'),
        inactive: require('@/sprite/home.jpg')
      },
      myIcon: {
        active: require('@/sprite/myOn.jpg'),
        inactive: require('@/sprite/my.jpg')
      }
    }
  },
  created() {},
  mounted() {},
  methods: {
    handleChange(idx) {
      console.log('idx', idx)
      console.log('active', this.active)
      // if (idx == 1) {
      //   this.$emit('onClickMy', this.isAuthorized)
      // }
    }
  }
}
</script>

<style lang="less" scoped>
.tabbar {
  .van-tabbar {
    // border-top: 1px solid #eeeeee;
    z-index: 99;
    padding-bottom: 44px;
    .van-tabbar-item{
      padding-top:12px;
    }
  }
  .icon{
    width: 24px;
    height: 24px;
  }
}
</style>
