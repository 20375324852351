const setToken = (type, code) => {
  return localStorage.setItem(type, code);
}

const getToken = (type) => {
  return localStorage.getItem(type);
}

const removeToken = (type) => {
  return localStorage.removeItem(type);
}

const sessionSet = (type, code) => {
  return sessionStorage.setItem(type, code);
}

const sessionGet = (type) => {
  return sessionStorage.getItem(type);
}

const sessionRemove = (type) => {
  return sessionStorage.removeItem(type);
}

const urlParse = query => {
  let obj = {};
  const reg = /[?&][^?&]+=[^?&]+/g;
  const arr = query.match(reg);
  if (arr) {
    arr.forEach((item) => {
      let tempArr = item.substring(1).split('=');
      let key = decodeURIComponent(tempArr[0]);
      let val = decodeURIComponent(tempArr[1]);
      obj[key] = val;
    });
  }
  return obj;
};

const weixinVersion = function () {
  var str = window.navigator.userAgent;
  var v0 = [6, 3, 31];
  var regExp = /MicroMessenger\/([\d|\.]+)/;
  if (regExp.exec(str) === null) { return }
  var v1 = regExp.exec(str)[1].split('.');
  if (v1.length >= 4) {
    v1 = v1.slice(0, 3);
  }
  v1 = v1.map(function (v) {
    return parseInt(v, 10);
  });
  if (v1[0] > v0[0]) {
    return true;
  }
  if (v1[0] === v0[0] && v1[1] > v0[1]) {
    return true;
  }
  if (v1[0] === v0[0] && v1[1] === v0[1] && v1[2] >= v0[2]) {
    return true;
  }
  return false;
}

const againLogin = (that, url) => {
  sessionRemove("token");
  removeToken("userType");
  that.$toast({
    message: "授权过期~请重新登录",
    duration: 2000,
    forbidClick: true,
    onClose: () => {
      that.$router.replace({
        path: "/",
        query: { redirect: url ? url : '/' }
      });

    }
  });

}

const numFilter = (value) => {
  // 截取当前数据到小数点后三位
  if (!value) return 0.00
  let tempVal = parseFloat(value).toFixed(3);
  let realVal = tempVal.substring(0, tempVal.length - 1);
  return realVal;
}

const isAlipayOrWechat = () => {
  var userAgent = navigator.userAgent.toLowerCase();
  if (userAgent.match(/Alipay/i) == "alipay") {
    // 支付宝
    return 1;
  } else if (userAgent.match(/MicroMessenger/i) == "micromessenger") {
    // 微信
    return 0;
  } else {
    // 浏览器
    return 2;
  }
}
const isMangguoApp = () => {
  return /imgo/i.test(window.navigator.userAgent);
}

//分端判断token是否存在
const splitEndJudgeToken = () => {
  switch (isAlipayOrWechat()) {
    case 0:
      return !sessionGet("token");
      break;
    case 1:
      return !sessionGet("token");
      break;
    case 2:
      return !getToken("token");
      break;
  }
}
//分端存储token及openId
const splitEndSetToken = (data) => {
  switch (isAlipayOrWechat()) {
    case 0:
      sessionSet("token", data.token);
      sessionSet("openId", data.openId);
      break;
    case 1:
      sessionSet("token", data.token);
      sessionSet("openId", data.openId);
      break;
    case 2:
      setToken("token", data.token);
      setToken("openId", data.openId);
      break;
  }
}
//分端获取token
const splitEndGetToken = () => {
  switch (isAlipayOrWechat()) {
    case 0:
      return sessionGet("token");
      break;
    case 1:
      return sessionGet("token");
      break;
    case 2:
      return getToken("token");
      break;
  }
}
//分端获得openId
const splitEndGetOpenId = () => {
  switch (isAlipayOrWechat()) {
    case 0:
      return sessionGet("openId");
    case 1:
      return sessionGet("openId");
      return;
    case 2:
      return getToken("openId");
  }
}

//分端删除token
const splitEndRemove = (data) => {
  switch (isAlipayOrWechat()) {
    case 0:
      return sessionRemove(data);
    case 1:
      return sessionRemove(data);
    case 2:
      return removeToken(data);
  }
}
// 替换url参数
const changeURLArg = (url, arg, arg_val) => {
  let pattern = arg + '=([^&]*)';
  let replaceText = arg + '=' + arg_val;
  if (url.match(pattern)) {
    let tmp = '/(' + arg + '=)([^&]*)/g';
    tmp = url.replace(eval(tmp), replaceText);
    return tmp;
  } else {
    if (url.match('[\?]')) {
      return url + '&' + replaceText;
    } else {
      return url + '?' + replaceText;
    }
  }
}
// 删除url参数
const delURLArg = (url, name) => {
  let baseUrl = url.split("?")[0];
  let query = url.split("?")[1];
  if (query.indexOf(name) > -1) {
    let obj = {}
    let arr = query.split("&");
    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].split("=");
      obj[arr[i][0]] = arr[i][1];
    };
    delete obj[name];
    let url = baseUrl + '?' + JSON.stringify(obj).replace(/[\"\{\}]/g, "").replace(/\:/g, "=").replace(/\,/g, "&");
    return url
  };
}

// 清空所有cookie
const clearCookie = () => {
  // console.log('document.cookie', document.cookie)

  var cookies = document.cookie.split(';')
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i]
    var eqPos = cookie.indexOf('=')
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
    document.cookie =
      name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/'
  }
  if (cookies.length > 0) {
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i]
      var eqPos = cookie.indexOf('=')
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
      var domain = location.host.substr(location.host.indexOf('.'))
      document.cookie =
        name +
        '=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=' +
        domain
    }
  }
}

// 芒果APP站内获取设备信息
const getDeviceInfo = () => {
  MgtvApi.getDeviceInfo(function (_device_info) {
    // 常用的字段：did: mac地址，appVersion：app版本
    console.log(_device_info, typeof _device_info)
    return _device_info
  })
}
// 会员数据上报（页面PV）
const MGTVVIP_page_view = (pageName, uuid) => {
  console.log('pageName：', pageName)
  console.log('uuid：', uuid)
  var act_name = 'mClub2022' // ID
  var act_name_chn = 'mClub分佣2022' // 中文名
  var device_info = isMangguoApp() ? getDeviceInfo() : null
  setTimeout(function () {
    MGTVVIP.init({
      act_name: act_name,
      act_name_chn: act_name_chn,
      user: {
        uuid: uuid, // uuid
      },
      source: act_name, // urlsource
      device_info: device_info, // 设备信息
      source_uuid: act_name,
    })
    // 页面PV
    MGTVVIP.page_view(pageName)
  }, 1000)
}
// 会员数据上报（点击事件）
const MGTVVIP_click_event = (eventName, uuid) => {
  console.log('eventName：', eventName)
  // console.log('uuid：', uuid)
  var act_name = 'mClub2022' // ID
  var act_name_chn = 'mClub分佣2022' // 中文名
  var device_info = isMangguoApp() ? getDeviceInfo() : null
  setTimeout(function () {
    MGTVVIP.init({
      act_name: act_name,
      act_name_chn: act_name_chn,
      user: {
        uuid: uuid, // uuid
      },
      source: act_name, // urlsource
      device_info: device_info, // 设备信息 
      source_uuid: act_name,
    })
    // 点击事件
    MGTVVIP.click_event(eventName)
  }, 1000)
}

const stringNum = (str,num) => {
  let arry = str.split("");
  let res = "";
  let s = 0;
  for(let i=0;i<arry.length;i++){
    let isletter = /^[a-zA-Z]+$/.test(arry[i]);
    if(isletter){
        s+=1;
    }else{
        s+=2;
    }

    if(s<=num){
        res+=arry[i];
    }else{
        res+="...";
        break;
    }
      
  }
  console.log(res);
  return res;
}

export {
  setToken,
  getToken,
  removeToken,
  sessionSet,
  sessionGet,
  sessionRemove,
  urlParse,
  againLogin,
  weixinVersion,
  numFilter,
  isAlipayOrWechat,
  splitEndGetToken,
  splitEndSetToken,
  splitEndJudgeToken,
  splitEndGetOpenId,
  splitEndRemove,
  changeURLArg,
  delURLArg,
  isMangguoApp,
  clearCookie,
  getDeviceInfo,
  MGTVVIP_page_view,
  MGTVVIP_click_event,
  stringNum,
}