import Vue from 'vue'
import Vuex from 'vuex'
import { Toast } from 'vant';
// 导入接口
import { GetUserInfo, GetUserAccountInfo } from '@/service/my'
import {} from '../utils/tokenUtils'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isDisabled: false, // 盟主账号是否被禁用
    userInfo: {}, // 盟主基础信息
    userAccountInfo: {}, // 盟主账户信息
  },
  mutations: {
    _isDisabled (state, isDisabled) {
      state.isDisabled = isDisabled
    },
    // 保存盟主基础信息
    _saveUserInfo (state, userInfo) {
      state.userInfo = userInfo
    },
    // 保存盟主账户信息
    _saveUserAccountInfo (state, userAccountInfo) {
      state.userAccountInfo = userAccountInfo
    },
  },
  actions: {
    // 获取盟主基础信息
    saveUserInfo (context) {
      GetUserInfo().then((res) => {
        const { code, data, message } = res
        if (code == '0') {
          context.commit('_saveUserInfo', data || {})
        } else if (code == '-1') {
          context.commit('_isDisabled', true)
          context.commit('_saveUserInfo', data || {})
          Toast(message)
        } else if(code == '401' && message == '未授权') {
          // Toast(message)
          context.commit('_saveUserInfo', {})
          localStorage.clear()
        }
      })
    },
    // 获取盟主账户信息
    saveUserAccountInfo (context) {
      GetUserAccountInfo().then((res) => {
        const { code, data, message } = res
        if (code == '0') {
          context.commit('_saveUserAccountInfo', data || {})
        } else {
          // Toast(message)
        }
      })
    },
  },
  modules: {
  },
})
