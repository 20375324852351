import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { getToken } from './utils/tokenUtils'
import {
    Form, Image as VanImage, Field, Button, Cell
    , Tabbar, TabbarItem, NavBar, Icon, Tab, Tabs, List,
    ActionSheet, Uploader, Toast, Loading, Skeleton, Swipe, SwipeItem,
    Lazyload, Progress, Overlay, Picker, Popover, Search, Tag, Popup, DatetimePicker, PullRefresh
} from 'vant';
// 引入
import tabbar from '@/components/tabbar.vue'
import InviteFriendsPopup from '@/components/InviteFriendsPopup.vue'
import ProductPoster from '@/components/ProductPoster.vue'
// 注册为全局组件
Vue.component('v-tabbar', tabbar)
Vue.component('InviteFriendsPopup', InviteFriendsPopup)
Vue.component('ProductPoster', ProductPoster)

Vue.use(VanImage);
Vue.use(Form);
Vue.use(Field);
Vue.use(Button);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(NavBar);
Vue.use(Icon);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(List);
Vue.use(Cell);
Vue.use(ActionSheet);
Vue.use(Uploader);
Vue.use(Toast);
Vue.use(Loading);
Vue.use(Skeleton);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Lazyload);
Vue.use(Progress);
Vue.use(Overlay);
Vue.use(Picker);
Vue.use(Popover);
Vue.use(Search);
Vue.use(Tag);
Vue.use(Popup);
Vue.use(DatetimePicker);
Vue.use(PullRefresh);

Vue.config.productionTip = false

// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
    console.log('router',to.path)
    // 是否需要登录才能访问的页面，true需要，false不需要
    if (!to.meta.isNeedLogin) {
        next()
    } else {
        
        // 如果token不存在，跳转到登录页面
        if (!getToken('token')) {
            window.location.href = `https://t.youku.com/yep/page/m/jca2kbelhq?wh_weex=true&isNeedBaseImage=1&redirect_url=${process.env.NODE_ENV == 'development'
            ? encodeURIComponent('http://test.youku.com/#/?superiorId=')
            : encodeURIComponent(window.location.origin+'/#/?superiorId=')
            }`
            next()
        } else {
            // 否则直接放行
            next()
        }

    }
})

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
