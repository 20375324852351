// 拦截器
import axios from 'axios';
import { Toast } from 'vant';
import router from '../router'
// import { removeToken } from '@/utils/tokenUtils'
// import baseURL from './baseURL'
// console.log(process.env, '什么环境process.env')
let baseURL = process.env.VUE_APP_BASEURL 
console.log('baseURL', baseURL)
// 创建请求实例
const Server = axios.create({
  baseURL: baseURL, // 初始配置公共请求URL
  timeout: 30000, // 默认超时时长
})

// 添加请求拦截器
Server.interceptors.request.use(config => {
  // 在发送请求之前做些什么
  // console.log('拦截处理请求')
  const token = window.localStorage.getItem('token')
  config.headers.token = token?token:''
  return config
}, error => {
  return Promise.reject(error)
});

// 添加响应拦截器
Server.interceptors.response.use(response => {
  // console.log('处理响应', response)
  console.log(response);
  if (response.status === 200) {
    if(process.env.NODE_ENV == 'development' || process.env.NODE_ENV == 'it'){
      if(response.data.code == '407'){
        router.push('/error')
      }
    }
    
    return Promise.resolve(response)
  } else {
    return Promise.reject(response)
  }
}, error => {
  console.log('error', error)
  if (error.response.status === 401) {
    Toast("无权限操作")
  }
  if (error.response.status === 400) {
    Toast("请求网络失败")
  }
  if (error.response.status === 404) {
    Toast("找不到接口")
  }
  return Promise.reject(error)
});

// 封装post,get方法
const http = {
  get (url = '', params = {}) {
    return new Promise((resolve, reject) => {
      Server({
        url,
        params,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
        method: 'GET'
      }).then(res => {
        resolve(res.data)
        return res
      }).catch(error => {
        reject(error)
      })
    })
  },
  post (url = '', params = {}) {
    return new Promise((resolve, reject) => {
      Server({
        url,
        data: params,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
        method: 'POST'
      }).then(res => {
        resolve(res.data)
        return res
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default http