import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('@/views/home/index.vue'),
        meta: {
            title: '酷联盟',
            isNeedLogin: false, // 是否需要登录才能访问的页面，true需要，false不需要
        },
    },
    {
        path: '/my',
        name: 'My',
        component: () => import('@/views/my/index.vue'),
        meta: {
            title: '酷联盟',
            isNeedLogin: false,
        },
    },
    {
        path: '/rankTop',
        name: 'RankTop',
        component: () => import('@/views/rankTop/index.vue'),
        meta: {
            title: '收益排行榜',
            isNeedLogin: false,
        },
    },
    {
        path: '/commissionDetails',
        name: 'CommissionDetails',
        component: () => import('@/views/commissionDetails/index.vue'),
        meta: {
            title: '',
            isNeedLogin: true,
        },
    },
    {
        path: '/eventRewards',
        name: 'EventRewards',
        component: () => import('@/views/eventRewards/index.vue'),
        meta: {
            title: '活动奖励',
            isNeedLogin: true,
        },
    },
    {
        path: '/withdrawalRecord',
        name: 'WithdrawalRecord',
        component: () => import('@/views/withdrawalRecord/index.vue'),
        meta: {
            title: '提现记录',
            isNeedLogin: true,
        },
    },
    {
        path: '/myTeam',
        name: 'MyTeam',
        component: () => import('@/views/myTeam/index.vue'),
        meta: {
            title: '我的团队',
            isNeedLogin: true,
        },
    },
    {
        path: '/myCommission',
        name: 'MyCommission',
        component: () => import('@/views/myCommission/index.vue'),
        meta: {
            title: '金额提现',
            isNeedLogin: true,
        },
    },
    {
        path: '/bindRealNameAccount',
        name: 'BindRealNameAccount',
        component: () => import('@/views/bindRealNameAccount/index.vue'),
        meta: {
            title: '绑定实名账户',
            isNeedLogin: true,
        },
    },
    {
        path: '/withdrawal',
        name: 'Withdrawal',
        component: () => import('@/views/withdrawal/index.vue'),
        meta: {
            title: '提现',
            isNeedLogin: true,
        },
    },
    {
        path: '/bindCard',
        name: 'BindCard',
        component: () => import('@/views/bindCard/index.vue'),
        meta: {
            title: '',
            isNeedLogin: true,
        },
    },
    {
        path: '/bindCard',
        name: 'BindCard',
        component: () => import('@/views/bindCard/index.vue'),
        meta: {
            title: '',
            isNeedLogin: true,
        },
    },
    {
        path: '/serveAgreement',
        name: 'ServeAgreement',
        component: () => import('@/views/serveAgreement/index.vue'),
        meta: {
            title: '推广大使服务协议',
            isNeedLogin: false,
        },
    },
    {
        path: '/commissionAgreement',
        name: 'CommissionAgreement',
        component: () => import('@/views/commissionAgreement/index.vue'),
        meta: {
            title: '佣金与行为规则',
            isNeedLogin: false,
        },
    },
    {
        path: '/raidersLeft',
        name: 'RaidersLeft',
        component: () => import('@/views/raidersLeft/index.vue'),
        meta: {
            title: '酷联盟指南',
            isNeedLogin: false,
        },
    },
    {
        path: '/raidersRight',
        name: 'RaidersRight',
        component: () => import('@/views/raidersRight/index.vue'),
        meta: {
            title: '',
            isNeedLogin: false,
        },
    },
    {
        path: '/shareExtension',
        name: 'ShareExtension',
        component: () => import('@/views/shareExtension/index.vue'),
        meta: {
            title: '成为推广大使赚小钱',
            isNeedLogin: false,
        },
    },
    {
        path: '/error',
        name: 'Error',
        component: () => import('@/views/error/index.vue'),
        meta: {
            title: 'error',
            isNeedLogin: false,
        },
    },
    // {
    //     path: '/grade',
    //     name: 'Grade',
    //     component: () => import('@/views/grade/index.vue'),
    //     meta: {
    //         title: '大使等级',
    //         isNeedLogin: true,
    //     },
    // },
    // {
    //     path: '/gradeRule',
    //     name: 'GradeRule',
    //     component: () => import('@/views/grade/rule.vue'),
    //     meta: {
    //         title: '等级体系规则',
    //         isNeedLogin: true,
    //     },
    // },
]

const router = new VueRouter({
    // mode: 'history',
    routes: routes,
})

export default router
