<template>
  <div class="inviteFriends">
    <!-- 邀请好友海报 -->
    <van-popup v-model="showPosterInvite"
               :close-on-click-overlay="false"
               overlay
               class="inviteFriends_popup">
      <div class="content_poster">
        <div class="shareWarp_invite"
             ref="inviteCanvasRef"
             :style="{ display: opacityInvite === 1 ? 'block' : 'none' }">
          <img class="bg"
               src="../sprite/invite_friends_bg.jpg"
               alt="">
          <div class="code_invite">
            <div class="qrcode_invite"></div>
          </div>
        </div>
        <div class="copyAndSaveBtn">
          <span @click="handleCopyAndSaveInvite('LINK')">链接分享</span>
          <span @click="handleCopyAndSaveInvite('IMG')">图片分享</span>
        </div>
        <div class="close_invite">
          <span @click="closePosterInvite"></span>
        </div>
      </div>
      <div class="postersClass_invite"></div>
    </van-popup>
    <van-action-sheet v-model="showSheetInvite"
                      title="选择分享方式">
      <div class="sheet-content_invite">
        <div @click="shareWechatHandleInvite('wechat')">
          <img src="../sprite/wechat_icon.png"
               alt="">
          <p>微信好友/群</p>
        </div>
        <div @click="shareWechatHandleInvite('moments')">
          <img src="../sprite/friends_icon.png"
               alt="">
          <p>朋友圈</p>
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>
<script>
import html2canvas from 'html2canvas'
import QRCode from 'qrcode'
import { isMangguoApp } from '@/utils/tokenUtils'
export default {
  props: {
    showPosterInvite: {
      type: Boolean
    },
    superiorUrlInvite: {
      type: String
    },
    pageType: {
      type: String
    }
  },
  data() {
    return {
      isApp: isMangguoApp(), // 判断是否在芒果APP中打开H5页面，（在APP中不展示 “退出登录”）
      showSheetInvite: false,
      opacityInvite: 1,
      inviteImg: '' // 邀请好友海报图片
    }
  },
  created() {},
  mounted() {
    this.handleInviteFriendsShowPoster()
  },
  methods: {
    handleInviteFriendsShowPoster() {
      if (
        document.getElementsByClassName('postersClass_invite')[0] &&
        document.getElementsByClassName('copyImg_invite')[0]
      ) {
        document
          .getElementsByClassName('postersClass_invite')[0]
          .removeChild(document.getElementsByClassName('copyImg_invite')[0])
      }
      if (
        document.getElementsByClassName('qrcode_invite')[0] &&
        document.getElementsByClassName('qrcodeImg_invite')[0]
      ) {
        document
          .getElementsByClassName('qrcode_invite')[0]
          .removeChild(document.getElementsByClassName('qrcodeImg_invite')[0])
      }
      this.opacityInvite = 1
      this.$nextTick(async () => {
        await this.creatQrCodeInvite()
        await this.drawCanvasInvite()
      })
    },
    creatQrCodeInvite() {
      const _this = this
      console.log('superiorUrlInvite', this.superiorUrlInvite)
      QRCode.toDataURL(
        _this.superiorUrlInvite,
        {
          margin: 0.2,
          quality: 0.3
        },
        (err, url) => {
          if (err) return console.log(err)
          const img = document.createElement('img')
          img.className = `qrcodeImg_invite`
          img.src = url
          document.getElementsByClassName(`qrcode_invite`)[0].appendChild(img)
        }
      )
    },
    parseValueInvite(value) {
      return parseInt(value, 10)
    },
    DPRInvite() {
      if (window.devicePixelRatio && window.devicePixelRatio > 1) {
        return window.devicePixelRatio
      }
      return 1
    },
    drawCanvasInvite() {
      const dom = this.$refs.inviteCanvasRef
      const box = window.getComputedStyle(dom)
      const width = this.parseValueInvite(box.width)
      const height = this.parseValueInvite(box.height)

      const scaleBy = this.DPRInvite()
      let canvas = document.createElement(`canvas`)
      // 设定 canvas 元素属性宽高为 DOM 节点宽高 * 像素比
      canvas.width = width * scaleBy
      canvas.height = height * scaleBy
      // 设定 canvas css宽高为 DOM 节点宽高
      canvas.style.width = `${width}px`
      canvas.style.height = `${height}px`
      canvas.style.borderRadius = '8px'
      // canvas.getContext("2d").scale(2, 2)

      let x = width
      let y = height
      const _this = this
      html2canvas(dom, {
        canvas,
        useCORS: true,
        logging: true,
        backgroundColor: 'transparent',
        dpi: 300
        // scale:1
        // borderRadius: '20px'
      }).then((canvas) => {
        _this.convertCanvasToImageInvite(canvas, x, y)
      })
    },
    // 邀请好友海报图片
    convertCanvasToImageInvite(canvas, x, y) {
      let image = new Image()
      image.width = x
      image.height = y
      image.className = `copyImg_invite`
      image.src = canvas.toDataURL('image/png')
      this.inviteImg = canvas.toDataURL('image/png')
      document
        .getElementsByClassName(`postersClass_invite`)[0]
        .appendChild(image)
      setTimeout(() => {
        this.opacityInvite = 0
      }, 300)
      return image
    },
    closePosterInvite() {
      if (
        document.getElementsByClassName('postersClass_invite')[0] &&
        document.getElementsByClassName('copyImg_invite')[0]
      ) {
        document
          .getElementsByClassName('postersClass_invite')[0]
          .removeChild(document.getElementsByClassName('copyImg_invite')[0])
      }
      if (
        document.getElementsByClassName('qrcode_invite')[0] &&
        document.getElementsByClassName('qrcodeImg')[0]
      ) {
        document
          .getElementsByClassName('qrcode_invite')[0]
          .removeChild(document.getElementsByClassName('qrcodeImg_invite')[0])
      }
      this.$emit('closePopup')
    },
    handleCopyAndSaveInvite(v) {
      // console.log(this.inviteImg, 'this.inviteImg')
      // return
      let LINKeventName = '',
        IMGeventName = ''
      if (this.pageType == 'home') {
        LINKeventName = '首页点击邀请分享链接'
        IMGeventName = '首页点击邀请分享图片'
      }
      if (this.pageType == 'my') {
        LINKeventName = '我的页面点击邀请分享链接'
        IMGeventName = '我的页面点击邀请分享图片'
      }
      if (this.pageType == 'myTeam') {
        LINKeventName = '我的团队页面点击邀请分享链接'
        IMGeventName = '我的团队页面点击邀请分享图片'
      }
      let mgUuid = localStorage.getItem('mgUuid')
      // console.log('mgUuid：', mgUuid)
      if (v == 'LINK') {
        const _this = this
        // 站内
        if (this.isApp) {
          MgtvApi.showShareMenus(
            {
              title: '邀请你成为M-CLUB推广大使，稳赚不赔！',
              desc: '招募芒果“合伙人”，月入过万，虚位以待！',
              shareUrl: _this.superiorUrlInvite,
              shareIcon:
                'https://fulu-tuike.oss-cn-hangzhou.aliyuncs.com/picture/01d5ea84b7b24d37ad2e9d2f503828da.png'
            },
            function (resp) {
              console.log('resp', resp)
            }
          )
          MgtvApi.showShare()
        }
        // 站外
        if (!this.isApp) {
          let Url = this.superiorUrlInvite
          let oInput = document.createElement('input')
          oInput.value = Url
          document.body.appendChild(oInput)
          oInput.select() // 选择对象
          if (document.execCommand('copy')) {
            document.execCommand('copy')
          }
          document.body.removeChild(oInput)
          this.$toast('链接复制成功，快去分享链接给好友吧！')
        }
      }
      if (v == 'IMG') {
        // 站内
        if (this.isApp) {
          this.showSheetInvite = true
        }
        // 站外
        if (!this.isApp) {
          this.$toast('请长按图片选择保存到手机')
        }
      }
    },
    shareWechatHandleInvite(type) {
      this.showSheetInvite = false
      MgtvApi.shareTo({
        type: type,
        style: '1',
        title: '邀请你成为M-CLUB推广大使，稳赚不赔！',
        shareUrl: this.inviteImg,
        shareDesc: '招募芒果“合伙人”，月入过万，虚位以待！',
        shareIcon: ''
      })
    }
  }
}
</script>
<style lang="less">
.inviteFriends {
  .inviteFriends_popup {
    background-color: transparent;
    overflow: visible;

    .content_poster {
      height: 15.3rem;
      width: 10rem;
      display: flex;
      justify-content: center;

      .shareWarp_invite {
        margin: 0 auto;
        width: 8rem;
        height: 12.53rem;
        display: block;
        // background: url('../sprite/invite_friends_bg.png') no-repeat;
        background-size: 100% 100%;
        border-radius: 0.187rem;
        box-sizing: border-box;
        position: relative;
        .bg {
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 0.187rem;
        }
        .code_invite {
          position: absolute;
          bottom: 0.92rem;
          left: 0.58rem;

          .qrcode_invite {
            img {
              width: 2.1rem;
              height: 2.1rem;
              display: block;
              border-radius: 0.20rem;
            }
          }
        }
      }

      .copyAndSaveBtn {
        position: absolute;
        bottom: 1.3rem;
        display: flex;
        width: 80%;
        justify-content: space-between;
        box-sizing: border-box;

        > span {
          width: 3.7rem;
          height: 1rem;
          line-height: 1rem;
          background: #FF006E;
          border-radius: 1rem;
          display: inline-block;
          color: #fff;
          font-size: 16px;
          text-align: center;
        }

        > span:nth-child(2) {
          background: #FF006E;
        }
      }

      .close_invite {
        position: absolute;
        bottom: 0rem;
        span{
          display: block;
          width: 0.9rem;
          height: 0.9rem;
          background: url(../sprite/close-icon.png) no-repeat;
          background-size: 100% 100%;
        }
      }
    }

    .postersClass_invite {
      position: fixed;
      top: 0;
      left: calc((100% - 8rem) / 2);

      > img {
        border-radius: 0.187rem;
        vertical-align: middle;
      }
    }
  }

  .sheet-content_invite {
    display: flex;
    justify-content: center;
    align-items: center;

    > div {
      padding: 0.5rem 0 1rem;
      width: 50%;
      text-align: center;
      box-sizing: border-box;

      > img {
        width: 1.17rem;
        height: 1.17rem;
        vertical-align: middle;
      }

      > p {
        margin-top: 0.2rem;
        text-align: center;
        height: 0.44rem;
        font-size: 0.32rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 0.44rem;
      }
    }
  }
}
</style>