import https from '@/request/index'
// 盟主基础信息
export const GetUserInfo = (params) => {
  return https.get('/api/User/GetUserInfo', params)
}
// 盟主账户信息
export const GetUserAccountInfo = (params) => {
  return https.post('/api/User/GetUserAccountInfo', params)
}
// 盟主当月订单数据汇总
export const GetUserOrderSummary = (params) => {
  return https.get('/api/UserCommisson/GetUserOrderSummary', params)
}
// 盟主当月订单数据汇总2.0
export const GetUserOrdeGetAllUserOrderSummaryrSummary = (params) => {
  return https.get('/api/UserCommisson/GetUserOrdeGetAllUserOrderSummaryrSummary', params)
}
// 安全退出
export const Logout = (params) => {
  return https.get('/api/User/Logout', params)
}
// 获取大使芒点值信息
export const GetUserMountPoint = (params) => {
  return https.get('/api/User/GetUserMountPoint', params)
}
// 团队统计
export const GetTeamOverview = (params) => {
  return https.get('/api/User/GetTeamOverview', params)
}
// 我的团队
export const GetTeamList = (params) => {
  return https.get('/api/User/GetTeamList', params)
}
// 我的邀请人
export const GetSuperiorInfo = (params) => {
  return https.get('/api/User/GetSuperiorInfo', params)
}
