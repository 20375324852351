<template>
    <div class="productPoster">
        <!-- 商品海报 -->
        <van-popup v-model="show"
                   :close-on-click-overlay="false"
                   overlay
                   class="shareModal">
            <div class="shareCentent">
                <van-loading type="spinner"
                             v-if="imgLoading"
                             color="#F0D19E"
                             vertical>生成中...</van-loading>
                <ul v-if="!imgLoading"
                    class="content-swipe">
                    <li v-for="(item, idx) in productSubImageList"
                        :key="idx">
                        <div class="shareWarp"
                             :ref="`canvasRef_${idx}`"
                             :style="{ display: opacity === 1 ? 'block' : 'none' }">
                            <div class="prodcutImg">
                                <img crossOrigin="anonymous"
                                     :src="item+'?'+new Date().getTime()"
                                     alt="">
                                <!-- <div class="price">¥<span>{{productPrice}}</span></div> -->
                            </div>
                            <div class="content">
                                <div class="userInfo">
                                    <img class="productImg"
                                         crossOrigin="anonymous"
                                         :src="userInfo && userInfo.avatarUrl+'?'+new Date().getTime()"
                                         alt="">
                                    <div>
                                        <img class="title"
                                             src="../assets/img/textTitle.png"
                                             alt="">
                                        <span class="name">{{userInfo && userInfo.nickName}}</span>
                                        <span class="tipText">邀您成为芒果TV会员</span>
                                        <img class="tip"
                                             src="../assets/img/textTip.png"
                                             alt="">
                                    </div>
                                </div>
                                <div class="code">
                                    <div class="qrcode"
                                         :class="`qrcode_${idx}`"
                                         ref="qrCodeUrl"></div>
                                    <span>微信扫码快速抢购</span>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <div class="copyAndSaveBtn">
                    <span v-if="!imgLoading"
                          @click="handleCopyAndSave('LINK')">分享链接</span>
                    <span v-if="!imgLoading"
                          @click="handleCopyAndSave('IMG')">分享海报</span>
                </div>
                <div class="close"
                     v-if="!imgLoading">
                    <van-icon size="34"
                              color="#ffffff"
                              name="close"
                              @click="closePoster" />
                </div>
            </div>
            <van-swipe class="posters-swipe"
                       :autoplay="0"
                       indicator-color="white"
                       @change="onChange">
                <van-swipe-item v-for="(item, idx) in postersList"
                                :key="idx">
                    <div :class="`postersClass_${idx}`"></div>
                </van-swipe-item>
            </van-swipe>
        </van-popup>
        <van-action-sheet v-model="showSheet"
                          title="选择分享方式">
            <div class="sheet-content">
                <div @click="shareWechatHandle('wechat')">
                    <img src="../sprite/wechat_icon.png"
                         alt="">
                    <p>微信好友/群</p>
                </div>
                <div @click="shareWechatHandle('moments')">
                    <img src="../sprite/friends_icon.png"
                         alt="">
                    <p>朋友圈</p>
                </div>
            </div>
        </van-action-sheet>
    </div>
</template>
<script>
import html2canvas from 'html2canvas'
import QRCode from 'qrcode'
import { isMangguoApp, MGTVVIP_click_event } from '@/utils/tokenUtils'
import { GetProductShareUrl } from '../service/home'
export default {
    props: {
        show: {
            type: Boolean
        },
        shareProduct: {
            type: Object
        },
        userInfo: {
            type: Object
        },
        activityInfo: {
            type: Object
        },
        pageType: {
            type: String
        }
    },
    data() {
        return {
            opacity: 1,
            productImg: '', // 海报商品图片
            productPrice: '', // 海报商品价格
            isApp: isMangguoApp(), // 判断是否在芒果APP中打开H5页面，（在APP中不展示 “退出登录”）
            imgLoading: false,
            productSubImageList: [], // 分享的商品主图
            shareData: {
                title: '',
                desc: '',
                shareUrl: '',
                shareIcon: ''
            },
            shareLink: '', // 分享链接
            postersImg: '', // 整张海报图
            postersImgList: [], // 整张海报图list
            i: 0, // 整张海报的索引
            postersList: [],
            showSheet: false,

            LINKeventName: '',
            IMGeventName: ''
        }
    },
    created() {
        if (this.pageType == 'activity') {
            this.LINKeventName = `${this.activityInfo.activityName}活动页-${this.shareProduct.productName}-点击邀请分享链接`
            this.IMGeventName = `${this.activityInfo.activityName}活动页-${this.shareProduct.productName}-点击邀请分享海报`
        }
    },
    mounted() {
        this.initShow()
    },
    methods: {
        // 生成海报
        initShow() {
            // console.log(this.shareProduct, '------')
            this.imgLoading = true
            this.productSubImageList = []
            this.postersList = []
            this.postersImgList = []
            this.productSubImageList =
                JSON.parse(
                    JSON.stringify(this.shareProduct.productSubImageList)
                ) || []
            this.productSubImageList.unshift(this.shareProduct.productImage)
            if (this.productSubImageList.length > 0) {
                this.productSubImageList.forEach((item, idx) => {
                    this.postersList.push('_' + idx)
                })
            }
            this.productImg = this.shareProduct.productImage
            this.productPrice = this.shareProduct.salePrice

            this.shareData.title = this.shareProduct.productName
            this.shareData.shareIcon = this.shareProduct.productImage

            this.show = true
            this.i = 0
            this.opacity = 1
            this.$nextTick(() => {
                setTimeout(async () => {
                    await this.getProductShareUrl(this.shareProduct.id)
                    this.imgLoading = false
                }, 1500)
            })
        },
        // 获取商品分享海报链接
        getProductShareUrl(ProductId) {
            GetProductShareUrl({
                ProductId: ProductId,
                EuReferer: isMangguoApp() ? 'APP' : 'H5',
                IsShortchain: false
            }).then((res) => {
                const { code, data } = res
                if (code == '0') {
                    this.shareData.shareUrl = data
                    this.shareLink = data // 分享链接
                    this.creatQrCode(data)
                }
            })
        },
        parseValue(value) {
            return parseInt(value, 10)
        },
        DPR() {
            if (window.devicePixelRatio && window.devicePixelRatio > 1) {
                return window.devicePixelRatio
            }
            return 1
        },
        drawCanvas() {
            const _this = this
            console.log('this.$refs', this.$refs)
            // const { qrCodeUrl, refRule, tipUser, ...others } = this.$refs
            // console.log('others', others)
            let obj = {}
            for (let k in this.$refs) {
                if (k.indexOf('canvasRef_') != -1) {
                    obj[k] = this.$refs[k]
                }
            }
            let arr1 = Object.values(obj)
            // console.log('arr1', arr1)
            this.postersImgList = arr1.length > 0 && new Array(arr1.length)
            arr1.length > 0 &&
                arr1.forEach((item, idx) => {
                    const dom = item[0]
                    const box = window.getComputedStyle(dom)
                    const width = this.parseValue(box.width)
                    const height = this.parseValue(box.height)

                    const scaleBy = this.DPR()
                    let canvas = document.createElement(`canvas`)
                    // 设定 canvas 元素属性宽高为 DOM 节点宽高 * 像素比
                    canvas.id = `canvansId_${idx}`
                    canvas.width = width * scaleBy
                    canvas.height = height * scaleBy
                    // 设定 canvas css宽高为 DOM 节点宽高
                    canvas.style.width = `${width}px`
                    canvas.style.height = `${height}px`
                    canvas.style.borderRadius = '8px'

                    let x = width
                    let y = height
                    html2canvas(item[0], {
                        canvas,
                        useCORS: true,
                        logging: true,
                        backgroundColor: 'transparent',
                        borderRadius: '20px'
                    }).then((canvas) => {
                        // console.log(canvas, 'canvas')
                        _this.convertCanvasToImage(canvas, x, y, idx)
                    })
                })
        },
        // 海报图片
        convertCanvasToImage(canvas, x, y, idx) {
            let image = new Image()
            image.width = x
            image.height = y
            image.className = `copyImg_${idx}`
            // image.src = `${idx}` // 自测用
            image.src = canvas.toDataURL('image/png')
            // console.log('image', image)
            this.shareData.shareUrl = canvas.toDataURL('image/png')
            document
                .getElementsByClassName(`postersClass_${idx}`)[0]
                .appendChild(image)
            this.opacity = 0
            // this.postersImgList.push(image.src)
            // this.postersImgList = ['1', '2', '3', '4']
            this.postersImgList.splice(idx, 1, image.src)
            // console.log('this.postersImgList', this.postersImgList)
            // return image;
        },
        creatQrCode(url) {
            const _this = this
            QRCode.toDataURL(
                url,
                {
                    margin: 0.2,
                    quality: 0.3
                },
                (err, url) => {
                    if (err) return console.log(err)
                    // console.log('this.$refs.qrCodeUrl', this.$refs.qrCodeUrl)
                    let QRS = this.$refs.qrCodeUrl
                    QRS &&
                        QRS.forEach((item, idx) => {
                            const img = document.createElement('img')
                            img.className = `qrcodeImg_${idx}`
                            img.src = url
                            document
                                .getElementsByClassName(`qrcode_${idx}`)[0]
                                .appendChild(img)
                        })
                    setTimeout(() => {
                        _this.drawCanvas()
                    }, 100)
                }
            )
        },
        closePoster() {
            // this.show = false
            this.productSubImageList = []
            this.postersList = []
            this.postersImgList = []
            this.$emit('closePopup')
        },
        onChange(i) {
            this.productImg = ''
            this.postersImg = ''
            // console.log('i', i)
            this.i = i
            // console.log('GGGG', this.productSubImageList)
            // console.log('HHHH', this.postersImgList)
            this.productImg = this.productSubImageList[i]
            this.postersImg = this.postersImgList[i]
        },
        handleCopyAndSave(v) {
            if (v == 'LINK') {
                setTimeout(() => {
                    // 埋点，会员数据上报（点击事件）
                    MGTVVIP_click_event(
                        this.LINKeventName,
                        this.userInfo.mgUuid
                    )
                }, 200)
                // 站内
                if (this.isApp) {
                    this.productImg = this.productSubImageList[this.i]
                    // console.log(this.shareLink, 'this.shareLink')
                    // console.log(this.shareData.title, 'this.shareData.title')
                    // console.log(this.productImg, 'productImg')
                    MgtvApi.showShareMenus(
                        {
                            title: this.shareData.title,
                            desc: '',
                            shareUrl: this.shareLink,
                            shareIcon: this.productImg
                        },
                        function (resp) {
                            console.log('resp', resp)
                        }
                    )
                    MgtvApi.showShare()
                }
                // 站外
                if (!this.isApp) {
                    let Url = this.shareLink
                    let oInput = document.createElement('input')
                    oInput.value = Url
                    document.body.appendChild(oInput)
                    oInput.select() // 选择对象
                    if (document.execCommand('copy')) {
                        document.execCommand('copy')
                    }
                    document.body.removeChild(oInput)
                    this.$toast('链接复制成功，快去分享链接给好友吧！')
                }
            }
            if (v == 'IMG') {
                setTimeout(() => {
                    // 埋点，会员数据上报（点击事件）
                    MGTVVIP_click_event(this.IMGeventName, this.userInfo.mgUuid)
                }, 200)
                // 站内
                if (this.isApp) {
                    this.showSheet = true
                    // console.log('this.i', this.i)
                    // console.log('this.postersImgList[i]', this.postersImgList[this.i])
                    this.postersImg = this.postersImgList[this.i]
                    console.log('this.postersImg', this.postersImg)
                }
                // 站外
                if (!this.isApp) {
                    this.$toast('请长按图片选择保存到手机')
                }
            }
        },
        shareWechatHandle(type) {
            this.showSheet = false
            console.log(this.shareData)
            console.log(this.postersImg, 'postersImg')
            MgtvApi.shareTo({
                type: type,
                style: '1',
                title: this.shareData.title,
                shareUrl: this.postersImg,
                shareDesc: this.shareData.desc,
                shareIcon: ''
            })
            this.productImg = ''
            this.postersImg = ''
        }
    }
}
</script>
<style lang="less">
.productPoster {
    .shareModal {
        background-color: transparent;
        overflow: visible;
        .shareCentent {
            height: 14rem;
            width: 10rem;
            display: flex;
            justify-content: center;
            // align-items: center;
            position: relative;
            .van-loading {
                position: absolute;
                padding-top: 0;
                width: 8rem;
                height: 10.98rem;
                background: linear-gradient(180deg, #fffbec, #fefefe);
                border-radius: 0.42rem;
                .van-loading__spinner {
                    margin-top: 50%;
                }
                .van-loading--vertical .van-loading__text {
                    margin: 0;
                }
            }
            .content-swipe {
                > li {
                    width: 10rem;
                }
                > li:nth-child(2) {
                    margin-top: 999999px;
                }
            }
            .shareWarp {
                margin: 0 auto;
                width: 8rem;
                height: 10.98rem;
                display: block;
                background: url('../sprite/shareBg.png') no-repeat;
                background-size: 100% 100%;
                padding: 0.25rem;
                box-sizing: border-box;
                .prodcutImg {
                    position: relative;
                    img {
                        width: 7.5rem;
                        height: 7.5rem;
                        border-radius: 0.2rem;
                    }
                    .price {
                        width: 2.52rem;
                        height: 1.23rem;
                        position: absolute;
                        bottom: 0;
                        right: -0.05rem;
                        background: linear-gradient(
                            71deg,
                            #fff6f1 0%,
                            #feeedd 100%
                        );
                        font-size: 0.5rem;
                        color: #ff290b;
                        line-height: 1.28rem;
                        text-align: center;
                        padding-left: 0.3rem;
                        box-sizing: border-box;
                        border-top-left-radius: 0.95rem;
                        border-bottom-right-radius: 0.3rem;
                        font-weight: 600;
                        span {
                            font-size: 0.66rem;
                        }
                    }
                }
                .content {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    // padding-top:0.10rem;
                    .userInfo {
                        display: flex;
                        align-items: center;
                        .productImg {
                            width: 1.06rem;
                            height: 1.06rem;
                            border-radius: 1.06rem;
                            vertical-align: middle;
                        }
                        div {
                            padding-left: 0.2rem;
                            position: relative;
                            span {
                                display: block;
                            }
                            .name {
                                width: 3.4rem;
                                color: #804919;
                                font-size: 0.4rem;
                                font-weight: 550;
                                margin-bottom: 0.15rem;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                -o-text-overflow: ellipsis;
                            }
                            .tipText {
                                color: #804919;
                                font-size: 0.24rem;
                            }
                            .title {
                                width: 1.74rem;
                                height: 0.42rem;
                                position: absolute;
                                top: -0.55rem;
                            }
                            .tip {
                                position: absolute;
                                bottom: -0.8rem;
                                left: -0.95rem;
                                width: 3.88rem;
                                height: 0.6rem;
                            }
                        }
                    }
                    .code {
                        .qrcode {
                            width: 2.52rem;
                            height: 2.52rem;
                            display: block;
                            padding: 0.1rem;
                            background: #fff;
                            box-sizing: border-box;
                            border-radius: 0.2rem;
                            img {
                                width: 2.32rem;
                                height: 2.32rem;
                                display: block;
                            }
                        }
                        span {
                            color: #804919;
                            font-size: 0.28rem;
                            display: block;
                            text-align: center;
                            transform: scale(0.95);
                        }
                    }
                }
            }
            .shareBtn {
                position: absolute;
                bottom: 1.4rem;
                display: flex;
                width: 100%;
                padding: 0 0.5rem;
                justify-content: space-between;
                box-sizing: border-box;
                span {
                    height: 1.1rem;
                    line-height: 1.1rem;
                    padding: 0 0.3rem;
                    border-radius: 1.1rem;
                    display: inline-block;
                    background: linear-gradient(
                        90deg,
                        #ffe0a2 0%,
                        #f6c371 100%
                    );
                    box-shadow: 0px 4px 16px -4px rgb(246 195 113 / 40%);
                    color: #804919;
                    font-size: 0.42rem;
                    text-align: center;
                }
            }
            .copyAndSaveBtn {
                position: absolute;
                bottom: 1.2rem;
                display: flex;
                width: 80%;
                padding: 0 0.5rem;
                justify-content: space-between;
                box-sizing: border-box;
                > span {
                    height: 1.17rem;
                    line-height: 1.17rem;
                    background: linear-gradient(
                        90deg,
                        #ffe0a2 0%,
                        #f6c371 100%
                    );
                    border-radius: 1.23rem;
                    padding: 0 0.8rem;
                    display: inline-block;
                    color: #804919;
                    font-size: 0.42rem;
                    text-align: center;
                }
                > span:nth-child(2) {
                    background: linear-gradient(
                        90deg,
                        #ffe0a2 0%,
                        #f6c371 100%
                    );
                }
            }
            .close {
                position: absolute;
                bottom: 0rem;
            }
        }
        .posters-swipe {
            width: 10rem;
            height: 11.7rem;
            position: fixed;
            top: 0;
            left: 0;
            display: flex;
            > img {
                border-radius: 0.3rem;
            }
            // .van-swipe__track {
            //   width: 10rem !important;
            // }
            .van-swipe-item {
                display: flex;
                justify-content: center;
            }
        }
    }
    .sheet-content {
        display: flex;
        justify-content: center;
        align-items: center;
        > div {
            padding: 0.5rem 0 1rem;
            width: 50%;
            text-align: center;
            box-sizing: border-box;
            > img {
                width: 1.17rem;
                height: 1.17rem;
                vertical-align: middle;
            }
            > p {
                margin-top: 0.2rem;
                text-align: center;
                height: 0.44rem;
                font-size: 0.32rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 0.44rem;
            }
        }
    }
}
@media only screen and (device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) {
    .home {
        .shareModal .shareCentent .shareWarp .content {
            .userInfo {
                .productImg {
                    width: 0.95rem;
                    height: 0.95rem;
                    vertical-align: middle;
                }
                .tipText {
                    font-size: 10px;
                    width: 115px;
                }
                div .name {
                    margin-bottom: 0.1rem;
                }
            }

            .code {
                margin-left: -5px;
                .qrcode {
                    margin-left: 7px;
                }
                span {
                    width: 97px;
                }
            }
        }
    }
}
</style>